import React from 'react'
import "./homepage.css"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

const HomePage = () => {
  const navigate = useNavigate()
  const commerciepage = () =>{

navigate('/commercie')
    
  }
  const weteepage = () =>{

navigate('/wetenschap')


  }

const homeAppearance = {
hidden:{
  opacity:0
},
visible:{
    opacity:1
,
  transition:{delay:0.5,duration:1}
},
exit:{
x:'-100vw',
transition:{ease:"easeInOut"}


}
}


    const {t} = useTranslation()
  return (
    <motion.div className='homepage container mt-4 mb-4 p-3 '
    
    variants={homeAppearance}
    initial="hidden"
    animate="visible"
    exit='exit'
    >
    <div className='text-center'>
     <h1>{t('Activa')}</h1>
    </div>
<div className="box bg-1 d-flex justify-content-evenly align-items-center pt-5 flex-wrap">
					<button  onClick={commerciepage} className="button button--wayra button--border-thick button--text-upper button--size-s">{t('commercie')}</button>
					<button onClick={weteepage} className="button button--wayra button--border-thick button--text-upper button--size-s">{t('wetenschap')}</button>
					
				</div>


    </motion.div>
  )
}

export default HomePage
import React from 'react'
import "./footer.css"
const Footer = () => {
  return (
    <div className='container text-center footer'>
        <p> &copy; 2016 - 2024 Sertic  </p>
    </div>
  )
}

export default Footer
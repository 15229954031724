import React from 'react'
import Form from '../../Components/Form/Form'
import "./wetenschappage.css"
const WetenschapPage = ({backToHome}) => {
  return (
    <div className='wetnshap d-flex align-items-center justify-content-center container text-center mt-4 mb-2 p-3 '>

<Form backToHome={backToHome}/>


    </div>
  )
}

export default WetenschapPage
import React,{useState,useEffect} from 'react'
import "./navbar.css"
import logo from "../../Assets/Sertic_logo.png"
import '../../../node_modules/flag-icon-css/css/flag-icons.min.css'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { motion } from 'framer-motion'
import {BsInfoCircle} from 'react-icons/bs'
import Popupemail from '../Popupemail/Popupemail'
const Navbar = () => {
  const [swapLang, setswapLang] = useState(true)
  const enlang = (e) =>{
    setswapLang(false)
    window.localStorage.setItem("SerticLang",false)
    i18next.changeLanguage("en")
    
}
const nllang = (e) =>{
    setswapLang(true)
    window.localStorage.setItem("SerticLang",true)

i18next.changeLanguage("nl")

}

const navBounceDown = {
hidden:{
  y:"-20vh"
},
visible:{
  y:0,
  transition:{type:"spring",duration:1,damping:8}
}


}




const [isopen,setisopen]=useState(false)
 const [close, setclose] = useState(false)

  
const location = useLocation();
useEffect(() => {
 
 if (window.localStorage.getItem("SerticLang") === "false"){
 setswapLang(false)
 }else if (window.localStorage.getItem("SerticLang") === "true"){
 setswapLang(true)

 }
 
  if (location.pathname === '/en'){
    localStorage.clear();
    setswapLang(false)
  }
  else if (location.pathname === '/nl'){
    localStorage.clear();
    setswapLang(true)
    
  }
}, [location])

const navigate = useNavigate()

const returnHome = () =>{
navigate('/')


}

const openEmailPopUp =() =>{
setclose(false)
setisopen(true)

} 


  return (
    <>
      < Popupemail isopen={isopen} setisopen={setisopen} close={close} setclose={setclose}/>

    <motion.div className='container navbar pt-4 d-flex align-items-center justify-content-between'
    variants={navBounceDown}
    initial="hidden"
    animate="visible"
    
    >
    <img onClick={returnHome} src={logo} alt="Sertic" className='sertic_logo' />
    <div className="justify-content-center d-flex flex-wrap align-items-center">
    <BsInfoCircle onClick={openEmailPopUp} className='info-email mx-md-3 mx-0' size={25}/>
    <div>
    <span onClick={() => nllang() } className={`text-capitalize  pe-2 lang ${swapLang ? 'active' : '' } `} style={{cursor:"pointer"}}><span className='flag-icon flag-icon-nl mx-2'></span>  dutch</span>
    <span>|</span>
    <span onClick={() => enlang()} className={`text-capitalize  pe-2 lang ${swapLang ? '' : 'active' } `} style={{cursor:"pointer"}}><span className='flag-icon flag-icon-gb mx-2'></span>english</span>
    </div>
    </div>
    </motion.div>
    </>
  )
}

export default Navbar
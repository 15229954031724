import React from 'react'
import "./commpage.css"
import {BiArrowBack} from "react-icons/bi"
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
const CommPage = ({backToHome}) => {

const commPageanimate= {
hidden:{
  x:'100vw'
},
visible:{
      x:0

,
  transition:{duration:0.5}
},
exit:{
x:'100vw',
transition:{ease:"easeInOut"}


}
}
const microsoftLink =() =>{
  window.open('https://www.microsoftsupplier.com', '_blank');
}
const staalixtLink =() =>{
  window.open('https://www.staalix.nl', '_blank');

}
const adobeLink =() =>{
  window.open('https://www.adobesupplier.com', '_blank');

}


  return (
  
    <motion.div className='commpage  container mt-4 mb-4 p-3 '
    
    variants={commPageanimate}
    initial="hidden"
    animate='visible'
    exit='exit'
    >
        <BiArrowBack onClick={backToHome} className='back-arrow' size={40}/>
    <div className="">
    <div className="box bg-1 d-flex justify-content-evenly align-items-center pt-5 flex-wrap">

					<button onClick={microsoftLink} style={{width:'fit-content',minWidth:'fit-content',maxWidth:'220px'}} className="button button--moema button--text-thick button--text-upper button--size-s">microsoft supplier</button>
					<button onClick={staalixtLink}  style={{width:'fit-content',minWidth:'fit-content',maxWidth:'220px'}} className="button button--moema button--text-thick button--text-upper button--size-s">staalix</button>
					
				</div>
    <div className="box bg-1 d-flex justify-content-evenly align-items-center pt-3  flex-wrap">

					<button onClick={adobeLink}  style={{width:'fit-content',minWidth:'fit-content',maxWidth:'220px'}} className="button button--moema button--text-thick button--text-upper button--size-s adobe">Adobe Supplier</button>
				</div>
				</div>
                </motion.div>
  )
}

export default CommPage
import './App.css';
import {Route , Routes, useLocation  } from "react-router-dom"
import Navbar from './Components/Navbar/Navbar';
import HomePage from './Pages/HomePage/HomePage';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.min'
import Footer from './Components/Footer/Footer';
import CommPage from './Pages/CommPage/CommPage';
import WetenschapPage from './Pages/WetenschapPage/WetenschapPage';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';


function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const backToHome =()=>{
navigate('/')

}
  return (
    <div className="App  ">
      <Navbar className="position-relative" />
      <AnimatePresence mode='wait' >

      <Routes location={location} key={location.key}>
     {/* <Route path='/en' exact  element={<HomePage/>}/> */}
     <Route path='/' exact  element={<HomePage/>}/>
     {/* <Route path='/nl' exact  element={<HomePage/>}/> */}
     <Route path='/commercie' exact  element={<CommPage backToHome={backToHome} />}/>
     <Route path='/wetenschap' exact  element={<WetenschapPage backToHome={backToHome} />}/>
      


      </Routes>
      </AnimatePresence>
      <Footer/>
    </div>
  )
}

export default App;

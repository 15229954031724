import React from 'react'
import "./form.css"
import {BiArrowBack} from "react-icons/bi"
import { motion } from 'framer-motion'
const Form = ({backToHome}) => {

const Formanimate = {
hidden:{
  scale:0
},
visible:{
     scale:1

,
  transition:{duration:1}
},
exit:{
  scale:0
,
transition:{ease:"easeInOut"}


}
}




  return (
     <motion.div className="login-box position-relative"
     
     variants={Formanimate}
     initial='hidden'
     animate='visible'
     exit='exit'
     >
        <BiArrowBack onClick={backToHome} className='back-arrow' size={40}/>
  <h2>Login</h2>
  <form>
    <div className="user-box">
      <input type="text" name="id" required=""/>
      <label>ID</label>
    </div>
    <div className="user-box">
      <input type="password" name="" required=""/>
      <label>Password</label>
    </div>
    <a href="#">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      Submit
    </a>
  </form>
</motion.div>     
   
  )
}


export default Form